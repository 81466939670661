/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export const ICON_GEAR = `
<svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5.20002 20.8624L7.06402 21.1477C8.44002 21.3584 9.42268 22.5904 9.32135 23.9784L9.18402
    25.8597C9.14402 26.4077 9.44268 26.9237 9.93735 27.1624L11.316 27.8264C11.8107 28.065 12.4013
    27.9757 12.8053 27.6037L14.1907 26.3237C15.212 25.3797 16.788 25.3797 17.8107 26.3237L19.196
    27.6037C19.6 27.977 20.1894 28.065 20.6854 27.8264L22.0667 27.161C22.56 26.9237 22.8574 26.409
    22.8174 25.8624L22.68 23.9784C22.5787 22.5904 23.5614 21.3584 24.9374 21.1477L26.8014 20.8624C27.344
    20.7797 27.7814 20.373 27.904 19.837L28.244 18.3464C28.3667 17.8104 28.1494 17.2544 27.696
    16.945L26.14 15.8797C24.992 15.093 24.6414 13.557 25.3347 12.3504L26.2747 10.7157C26.548 10.2397
    26.5027 9.6437 26.16 9.21437L25.2067 8.01837C24.864 7.58904 24.2934 7.4117 23.768 7.57304L21.9654
    8.12504C20.6334 8.53304 19.2134 7.84904 18.7014 6.55437L18.0107 4.8037C17.808 4.2917 17.3133
    3.9557 16.7627 3.95704L15.2347 3.96104C14.684 3.96237 14.1907 4.30104 13.9907 4.81437L13.3174
    6.54504C12.8107 7.8477 11.384 8.53704 10.048 8.12637L8.17068 7.55037C7.64402 7.3877 7.07068
    7.56637 6.72802 7.99837L5.78135 9.1957C5.43868 9.62904 5.39735 10.2264 5.67602 10.7024L6.63735
    12.341C7.34535 13.549 6.99868 15.0997 5.84402 15.8904L4.30668 16.9437C3.85335 17.2544 3.63602
    17.8104 3.75868 18.345L4.09868 19.8357C4.22002 20.373 4.65735 20.7797 5.20002 20.8624V20.8624Z"
  />
  <path
    d="M18.5548 13.4447C19.9655 14.8554 19.9655 17.1434 18.5548 18.5541C17.1441 19.9647 14.8561
    19.9647 13.4455 18.5541C12.0348 17.1434 12.0348 14.8554 13.4455 13.4447C14.8561 12.0341 17.1441
    12.0341 18.5548 13.4447"
  />
</svg>
`;

@Component({
    selector: 'flx-icon-gear',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'flx-icon thin' },
    template: ICON_GEAR,
    styleUrls: ['./icons.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IconGearComponent {
  @HostBinding('class.selected')
  @Input()
  selected = false;
}
