/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export const ICON_TARGET = `
<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <flx-shine *ngIf="shine"></flx-shine>
  <path d="M12.9004 11.0859L5.69736 18.2889" />
  <path d="M10.1992 11.0859H12.9004V13.7871" />
  <path
    d="M8.3985 20.2376C9.50196 20.7198 10.7185 20.9919 12 20.9919C16.9731 20.9919 21.0037 16.9612 21.0037
    11.9881C21.0037 7.01505 16.9731 2.98438 12 2.98438C7.02693 2.98438 2.99625 7.01505 2.99625
    11.9881C2.99625 13.2827 3.27436 14.5102 3.76657 15.6226"
  />
  <path
    d="M11.0996 17.3104C11.3927 17.3614 11.6929 17.3904 12 17.3904C14.9832 17.3904 17.4022 14.9714 17.4022
    11.9882C17.4022 9.00494 14.9832 6.58594 12 6.58594C9.01676 6.58594 6.59775 9.00494 6.59775
    11.9882C6.59775 12.2953 6.62676 12.5954 6.67778 12.8886"
  />
</svg>
`;
@Component({
    selector: 'flx-icon-target',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'flx-icon extra-thin' },
    template: ICON_TARGET,
    styles: [
        `
      :host svg {
        stroke-width: 1.5;
      }
    `,
    ],
    styleUrls: ['./icons.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IconTargetComponent {
  @HostBinding('class.shine')
  @Input()
  shine = false;
}
