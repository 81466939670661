/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export const ICON_UNAVAILABLE = `
  <span class="material-icons">block</span>
`;

@Component({
    selector: 'flx-icon-unavailable',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'flx-icon' },
    template: ICON_UNAVAILABLE,
    styleUrls: ['./icons.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IconUnavailableComponent {
  @HostBinding('class.shine')
  @Input()
  shine = false;
}
