/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

export const ICON_TIME = `
<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9 2H13" />
  <path d="M8.17004 10.1719L11 13.0019" />
  <path d="M19 5L16.66 7.34" />
  <path d="M18 4L20 6" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M22 15.5C22 13.567 20.388 12 18.4 12C16.412 12 14.8 13.567 14.8 15.5C14.8 18.125 18.4 21 18.4 21C18.4 21 22 18.125 22 15.5Z"
  />
  <path
    d="M18.399 15.5C18.261 15.5 18.149 15.612 18.15 15.75C18.15 15.888 18.262 16 18.4 16C18.538 16 18.65 15.888 18.65 15.75C18.65 15.612 18.538 15.5 18.399 15.5"
  />
  <path
    d="M18.939 12.053C18.47 8.082 15.097 5 11 5C6.582 5 3 8.582 3 13C3 17.418 6.582 21 11 21C13.061 21 14.934 20.214 16.352 18.934"
  />
</svg>
`;

@Component({
    selector: 'flx-icon-time',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'flx-icon thin' },
    template: ICON_TIME,
    styleUrls: ['./icons.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IconTimeComponent {}
