/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component } from '@angular/core';

export const ICON_BICYCLE = `
<svg viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.4899 16.5428L12.6195 14.0172L21.9982 11.1641L15.1724 20.7201H12.4421H6.98145L10.4899 16.5428Z"
  />
  <path
    d="M12.4422 20.7185C12.4484 23.2867 10.6682 25.5139 8.16178 26.0738C5.65534 26.6337 3.09664 25.3758 2.00932 23.0491C0.922012 20.7224 1.59849 17.9526 3.63593 16.3891C5.67337 14.8256 8.52386 14.8888 10.49 16.5411"
  />
  <circle cx="26.0934" cy="20.7184" r="5.46062" />
  <path d="M21.9985 8.43547L24.7288 7.07031" />
  <path d="M26.094 20.72L21.9985 8.43359" />
  <path d="M9.71191 9.79977H13.8074" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M21.9982 11.1641L15.1724 20.7201H6.98145L12.6195 14.0131L21.9982 11.1641Z"
  />
  <path d="M15.1726 20.722L11.0771 9.80078" />
</svg>
`;
@Component({
    selector: 'flx-icon-bicycle',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'flx-icon thin' },
    template: ICON_BICYCLE,
    styleUrls: ['./icons.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IconBicycleComponent {}
