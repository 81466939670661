/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export const ICON_HOUSE = `
<svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <flx-shine *ngIf="shine"></flx-shine>
  <path d="M6 21.428L24 6L42 21.428" />
  <path d="M8.57227 19.224V42H39.4283V6H31.7143V12.612" />
  <path
    d="M18.8574 41.9986V33.1406C18.8574 30.9306 20.6474 29.1406 22.8574 29.1406H25.1434C27.3534
    29.1406 29.1434 30.9306 29.1434 33.1406V41.9986"
  />
</svg>
`;

@Component({
    selector: 'flx-icon-house',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'flx-icon' },
    template: ICON_HOUSE,
    styleUrls: ['./icons.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IconHouseComponent {
  @HostBinding('class.shine')
  @Input()
  shine = false;
}
