import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export const ICON_LOCKER = `
<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <flx-shine *ngIf="shine"></flx-shine>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.99754 2.99658H18.0025C19.108 2.99658 20.0034 3.89195 20.0034 4.99742V19.0032C20.0034 20.1087 19.108 21.0041 18.0025 21.0041H5.99754C4.89208 21.0041 3.9967 20.1087 3.9967 19.0032V4.99742C3.9967 3.89195 4.89208 2.99658 5.99754 2.99658Z" />
  <path d="M8.99874 11V13.0008"/>
  <path d="M3.9967 19.0012C3.9967 20.4889 5.56236 21.4563 6.89191 20.791L10.8936 18.7902C11.5719 18.45 12 17.7577 12 16.9994V2.99658"/>
</svg>
`;

@Component({
    selector: 'flx-icon-locker',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'flx-icon' },
    template: ICON_LOCKER,
    styles: [
        `
      :host {
        svg path {
          fill: none;
          stroke-width: 1.5;
          stroke: currentColor;
        }
        &.shine svg path {
          stroke: var(--shineUrl);
        }
      }
    `,
    ],
    styleUrls: ['./icons.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IconLockerComponent {
  @HostBinding('class.shine')
  @Input()
  shine = false;
}
