/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component } from '@angular/core';

export const ICON_CHECKMARK = `
<svg viewBox="0 0 136 136" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M110.31 37.8984L67.9968 80.059L50.78 62.8422" />
  <path
    d="M114.965 57.3378C120.11 79.9628 108.401 103.042 87.1043 112.252C65.8076 121.461 40.9716 114.186 28.0104
    94.9408C15.0492 75.6957 17.6436 49.9464 34.1835 33.6737C50.7234 17.4011 76.5116 15.2265 95.543 28.4994"
  />
</svg>
`;

@Component({
    selector: 'flx-icon-checkmark',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'flx-icon' },
    template: ICON_CHECKMARK,
    styles: [
        `
      svg {
        stroke: currentColor;
        stroke-width: 8.5;
        stroke-linecap: 'round';
        stroke-linejoin: 'round';
      }
    `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IconCheckmarkComponent {}
