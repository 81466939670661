/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

export const ICON_ECO = `
<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M11.6208 8.36763C9.61461 8.36763 7.98828 9.99397 7.98828 12.0001C7.98828 14.0063 9.61461 15.6327 11.6208 15.6327C14.58 15.6327 15.3063 11.9991 17.002 11.0307C15.7378 9.33307 13.7373 8.34307 11.6208 8.36763Z"
  />
  <path d="M12.059 11.6055C10.0276 11.9453 8.23736 13.1348 7.13696 14.8758" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12 21.0036C16.9726 21.0036 21.0037 16.9725 21.0037 11.9998C21.0037 7.02721 16.9726 2.99609 12 2.99609C7.02733 2.99609 2.99622 7.02721 2.99622 11.9998C2.99622 16.9725 7.02733 21.0036 12 21.0036Z"
  />
</svg>
`;
@Component({
    selector: 'flx-icon-eco',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'flx-icon thin' },
    template: ICON_ECO,
    styleUrls: ['./icons.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IconEcoComponent {}
