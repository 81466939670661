/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component } from '@angular/core';

export const ICON_CLOSE = `
<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12 21C7.029 21 3 16.971 3 12C3 7.029 7.029 3 12 3C16.971 3 21 7.029 21 12C21 16.971 16.971 21 12 21Z"
  />
  <path d="M14.83 9.17188L9.16998 14.8319" />
  <path d="M14.83 14.8319L9.16998 9.17188" />
</svg>
`;

@Component({
    selector: 'flx-icon-close',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'flx-icon extra-thin' },
    template: ICON_CLOSE,
    styles: [
        `
      svg {
        fill: currentColor;
        stroke: var(--background) !important;
      }
    `,
    ],
    styleUrls: ['./icons.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IconCloseComponent {}
