/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export const ICON_WALK = `
<svg viewBox="0 0 50.23347 50.233469" xmlns="http://www.w3.org/2000/svg">
  <flx-shine *ngIf="shine"></flx-shine>
  <path d="m 30.132172,10.2249 c 0.263164,1.437635 -0.646525,2.808768 -2.031903,3.062326 -1.38538,0.253558 -2.721885,-0.706143 -2.98517,-2.143897 -0.263165,-1.4376352 0.646524,-2.8087682 2.031904,-3.0623263 1.385379,-0.2536954 2.721884,0.7061428 2.985169,2.1438973 m -2.138719,21.848403 -2.426816,-4.040701 -1.708043,4.776656 -3.380471,5.147771 c -0.550184,0.935639 -1.193775,1.526734 -2.104271,1.272098 -0.910498,-0.252874 -1.5411,-1.262042 -1.121287,-2.163532 l 3.452898,-5.724203 1.504856,-5.488063 c 0.08908,-0.504243 -0.225521,-1.316274 -0.225521,-1.316274 -0.296247,-1.129937 -0.09481,-1.801122 -0.09481,-1.801122 l 0.893887,-5.135801 -2.222462,1.929727 -0.912832,3.897941 c -0.142979,0.815682 -0.584661,1.423269 -1.341084,1.429794 -0.757112,0.0065 -1.377658,-0.664752 -1.309509,-1.37263 l 1.175188,-4.8416 c 0.145087,-0.947789 0.682738,-1.26309 0.682738,-1.26309 l 4.864048,-3.486718 c 2.828281,-1.086779 4.670406,0.874134 4.670406,0.874134 l 2.362857,4.722183 2.668461,2.037022 c 0.57251,0.617044 0.646165,1.48148 0.08476,2.006523 -0.482364,0.451031 -1.405734,0.145787 -1.865769,-0.329337 L 29.322709,21.438515 C 28.865814,21.163777 27.814352,18.97767 27.814352,18.97767 l -0.710333,4.625213 c -0.118899,0.569579 0.171274,0.934471 0.171274,0.934471 l 3.644145,5.839729 1.016056,6.698689 c 0.238847,1.0986 -0.2489,1.886929 -1.095458,2.105649 -0.891792,0.230894 -1.725701,-0.304918 -1.944781,-1.18336 z"/>
</svg>
`;

@Component({
    selector: 'flx-icon-walk',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'flx-icon extra-thin' },
    template: ICON_WALK,
    styleUrls: ['./icons.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IconWalkComponent {
  @HostBinding('class.shine')
  @Input()
  shine = false;
}
