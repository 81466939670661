/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export const ICON_PLUS = `
<svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <flx-shine *ngIf="shine"></flx-shine>
  <circle cx="20" cy="20" r="16" *ngIf="!noCircle"/>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M20.0001 9.33398C19.2111 9.33398 18.5715 9.97358 18.5715 10.7626V18.6669H9.90487C9.16849
    18.6669 8.57153 19.2639 8.57153 20.0003C8.57153 20.7367 9.16849 21.3336 9.90487
    21.3336H18.5715V29.2387C18.5715 30.0277 19.2111 30.6673 20.0001 30.6673C20.7891 30.6673
    21.4287 30.0277 21.4287 29.2387V21.3336H30.0953C30.8317 21.3336 31.4287 20.7367 31.4287
    20.0003C31.4287 19.2639 30.8317 18.6669 30.0953 18.6669H21.4287V10.7626C21.4287 9.97358
    20.7891 9.33398 20.0001 9.33398Z"
  />
</svg>
`;

@Component({
    selector: 'flx-icon-plus',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'flx-icon' },
    template: ICON_PLUS,
    styles: [
        `
      :host {
        svg path {
          stroke: none;
          fill: currentColor;
        }
        &.shine svg path {
          fill: var(--shineUrl);
        }
      }
    `,
    ],
    styleUrls: ['./icons.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IconPlusComponent {
  @HostBinding('class.shine')
  @Input()
  shine = false;

  @Input() noCircle = false;
}
