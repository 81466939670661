/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export const ICON_STAR = `
<svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M16 3.86133L19.9134 11.852L28.6667 13.1413L22.3334 19.3573L23.828 28.1387L16 23.9907L8.17204
    28.1387L9.66671 19.3573L3.33337 13.1413L12.0854 11.852L16 3.86133Z"
  />
</svg>
`;

@Component({
    selector: 'flx-icon-star',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'flx-icon extra-thin' },
    template: ICON_STAR,
    styles: [
        `
      :host:not(.selected) path {
        opacity: 0.55;
      }
      :host.selected path {
        fill: var(--warning);
      }
    `,
    ],
    styleUrls: ['./icons.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IconStarComponent {
  @HostBinding('class.selected')
  @Input()
  selected = false;
}
