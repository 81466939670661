/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'flx-icon-workplace',
    template: `
    <ng-container>
      @switch (type) {
        @case ('autoBooking') {
          <flx-icon-autobooking [shine]="shine"></flx-icon-autobooking>
        }
        @case ('office') {
          <flx-icon-office [shine]="shine"></flx-icon-office>
        }
        @case ('home') {
          <flx-icon-house [shine]="shine"></flx-icon-house>
        }
        @case ('flex') {
          <flx-icon-flex [shine]="shine"></flx-icon-flex>
        }
        @case ('unavailable') {
          <flx-icon-unavailable [shine]="shine"></flx-icon-unavailable>
        }
        @case ('mesh') {
          <flx-icon-mesh [shine]="shine"></flx-icon-mesh>
        }
        @case ('target') {
          <flx-icon-target [shine]="shine"></flx-icon-target>
        }
      }
    </ng-container>
  `,
    styles: [
        `
      :host {
        display: grid;
      }
    `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class WorkplaceIconComponent {
  @Input() type = 'office';
  @Input() shine = false;
}
